import logo from "./logo.svg";
import "./App.css";
import Header from "./Components/Header/Header";
import HeaderComponent from "./Components/HeaderComponent/HeaderComponent";
import Stakeholder from "./Components/Stakeholder/Stakeholder";
import WhtISCryp from "./Components/WhtISCryp/WhtISCryp";
import Platform from "./Components/Platform/Platform";
import FAQ from "./Components/FAQ/FAQ";
import AutoSlider from "./Components/AutoSlider/AutoSlider";
import Footer from "./Components/Footer/Footer";
import Home from "./Pages/Home";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signup from "./Components/Signup/Signup";
import SignupPage from "./Pages/SignupPage";
import LoginPage from "./Pages/LoginPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import AmbassadorPage from "./Pages/AmbassadorPage";
import UserAdmin from "./Components/Admin/UserAdmin/UserAdmin";
import LoginAdminPage from "./Pages/LoginAdminPage";
import AmbassadorsAdmin from "./Components/Admin/AmbassadorsAdmin/AmbassadorsAdmin";
import AmbassadorsVerified from "./Components/Admin/AmbassadorVerified/AmbassadorVerified";
import Investors from "./Components/Admin/Investors/Investors";
import Founders from "./Components/Admin/Founders/Founders";
import Others from "./Components/Admin/Other/Other";
import CommingSoonPage from "./Pages/CommingSoonPage";
import PartnerLogos from "./Components/Admin/PartnersLogos/PartnersLogos";
import VerificationCodePage from "./Pages/VerificationCodePage";
import ForgotPasswordPage from "./Pages/ForgotPasswordPage";
import ResetPasswordPage from "./Pages/ResetPasswordPage";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/user/signup" element={<SignupPage />} />
          <Route path="/user/login" element={<LoginPage />} />
          <Route path="/user/join/crypto" element={<AmbassadorPage />} />
          <Route path="/admin/users" element={<UserAdmin />} />
          <Route path="/admin/login" element={<LoginAdminPage />} />
          <Route path="/admin/ambassadors" element={<AmbassadorsAdmin />} />
          <Route path="/admin/amb/verified" element={<AmbassadorsVerified />} />
          <Route path="/admin/investors" element={<Investors />} />
          <Route path="/admin/founders" element={<Founders />} />
          <Route path="/admin/others" element={<Others />} />
          <Route path="/admin/parntners" element={<PartnerLogos/>} />
          <Route path="/user/verify" element={<VerificationCodePage/>} />

          

          <Route path="/user/privacy/policy" element={<PrivacyPolicy />} />
          <Route path="/user/coming/soon" element={<CommingSoonPage/>} />
          <Route path="/user/forgot/password" element={<ForgotPasswordPage/>} />
          <Route path="/user/reset/password/:id" element={<ResetPasswordPage/>} />


          
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
