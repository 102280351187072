import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import "./Header.css";
import Nav from "react-bootstrap/Nav";
import Cookies from "universal-cookie";
import Tippy from "@tippyjs/react";
import { HashLink } from "react-router-hash-link";

import { BsArrowRight } from "react-icons/bs";
import { GoThreeBars } from "react-icons/go";
import "tippy.js/dist/tippy.css"; // optional

import { FaRegEdit } from "react-icons/fa";
import Navbar from "react-bootstrap/Navbar";

import logo from "./../../images/logo-crypto.png";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
const cookies = new Cookies();
const Header = () => {
  const token = cookies.get("token");
  const [navbarColor, setNavbarColor] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setNavbarColor(true);
    } else {
      setNavbarColor(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  const logoutClicked = () => {
    cookies.remove("token", { path: "/" });
    // cookies.remove('_id', { path: '/' })
    // window.location.reload(true)

    window.location.assign("/");
  };

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleToggle = () => {
    setExpanded(!expanded);

    console.log("Clicked");
  };

  console.log("expanded", expanded);
  return (
    <>
      <Navbar
        bg="light"
        expand="lg"
        onToggle={handleToggle}
        expanded={expanded}

        // fixed={"top"}
      >
        <Container fluid className={navbarColor ? "navbar active" : "navbar"}>
          <Navbar.Brand href="#" className="navbarBrand">
            <img src={logo} className="logoImg" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            style={{ color: "white" }}
          >
            <GoThreeBars className="threeLine" />
          </Navbar.Toggle>
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className=" my-2 my-lg-0 firstNav"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link>
                <Tippy
                  content={
                    <span className="mainContentTippy">
                      <Link to="/user/coming/soon">
                        {" "}
                        <span>Calendar</span>
                      </Link>
                      <Link to="/user/coming/soon">
                        {" "}
                        <span>Meet-ups</span>
                      </Link>
                      <Link to="/user/coming/soon">
                        {" "}
                        <span>Demo Days</span>
                      </Link>
                      <Link to="/user/coming/soon">
                        {" "}
                        <span>Summit</span>
                      </Link>
                    </span>
                  }
                  interactive={true}
                  placement="bottom"
                >
                  <button className="btnTippies"> Events</button>
                </Tippy>
              </Nav.Link>
              <Nav.Link>
                <Tippy
                  content={
                    <span className="mainContentTippy">
                      <HashLink
                        onClick={handleToggle}
                        to={screenWidth <= "450" ? "#applyHere3" : "#applyHere"}
                      >
                        <span> About</span>
                      </HashLink>

                      {/* {screenWidth<="450"? 
                    
                      <Navbar.Toggle>
                      <HashLink
                        to={screenWidth <= "450" ? "#applyHere3" : "#applyHere"}
                      >
                        <span> About</span>
                      </HashLink>

                      </Navbar.Toggle>
                      :
                       <HashLink
                       to={screenWidth <= "450" ? "#applyHere3" : "#applyHere"}
                     >
                       <span> About</span>
                     </HashLink>
                      } */}
                      {/* <Navbar.Toggle></Navbar.Toggle> */}
                      <HashLink
                        to="#mainContainerWhyCrypto"
                        onClick={handleToggle}
                      >
                        <span> Why Join</span>
                      </HashLink>
                      <HashLink to="#mainFaq" onClick={handleToggle}>
                        <span> FAQ</span>
                      </HashLink>

                      <HashLink to="#mainContainerVideo" onClick={handleToggle}>
                        <span> Podcast</span>
                      </HashLink>
                    </span>
                  }
                  interactive={true}
                  placement="bottom"
                >
                  <button className="btnTippies"> Learn</button>
                </Tippy>
              </Nav.Link>
              <Nav.Link>
                <Tippy
                  content={
                    <span className="mainContentTippy">
                      <Link to="/user/coming/soon">
                        {" "}
                        <span>ESG</span>
                      </Link>
                      <Link to="/user/coming/soon">
                        {" "}
                        <span>Clean Tech</span>
                      </Link>
                      <Link to="/user/coming/soon">
                        {" "}
                        <span>Clean Energy</span>
                      </Link>
                      <Link to="/user/coming/soon">
                        {" "}
                        <span>Smart Cities</span>
                      </Link>
                    </span>
                  }
                  interactive={true}
                  placement="bottom"
                >
                  <button className="btnTippies"> Invest </button>
                </Tippy>
              </Nav.Link>
            </Nav>
            <Nav
              className=" my-2 my-lg-0 topLogoNav"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link href="/">
                <img src={logo} className="logoImg logoImgHide" />
              </Nav.Link>
            </Nav>

            <Nav
              className=" my-2 my-lg-0 topLogSign"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link className="loginNavApply">
                <Link to="/user/join/crypto"> Apply</Link>
                <BsArrowRight className="rightArrowApply" />
              </Nav.Link>
              {!token && (
                <Nav.Link className="loginNav">
                  <Link to="/user/login">Login</Link>
                </Nav.Link>
              )}

              {!token && (
                <Nav.Link className="loginNav">
                  <Link to="/user/signup">Signup</Link>
                </Nav.Link>
              )}

              {token && (
                <Nav.Link className="loginNav" onClick={logoutClicked}>
                  Logout
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
