import React, { useState } from "react";
import "./Signup.css";
import uploader from "./../../images/uploader.png";
import axios from "axios"
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { SERVERURL } from "../../ServerUrl";

const Signup = () => {

const navigate=useNavigate();
  const[valid,setValid]=useState()
  const[user,setUser]=useState({
    fullName:"",
    title:"",
    location:"",
    twitter:"",
    linkedIn:"",
    email:"",
    password:"",
    confirmPassword:"",
    image:""


  })
const handleChange=(e)=>{
  const{value,name}=e.target;

  setUser({...user,[name]:value})

}



  const [imgPreview, setImgPreview] = useState(null);
  const[continue1,setContinue]=useState(true);
  const [image, setImage] = useState(null);
  const handleImageChange = (e) => {
    const selected = e.target.files[0];
    // console.log("AHSAM2",selected)

    setUser({...user,image:selected})

    setImage(selected);

    const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setImgPreview(reader.result);
      };
      reader.readAsDataURL(selected);
    } else {
      //   setError(true);
    }
  };

  const continueClicked=()=>{

    if(user.fullName&&user.title&&user.location&&user.twitter&&user.linkedIn)
    {
      setContinue(false)
    }
    else{
      setValid(1)
    }
 

  }
  const signupClick=async ()=>{

    console.log("UserClick",user)

    if(user.fullName&&user.title&&user.location&&user.twitter&&user.linkedIn&&user.password&&user.confirmPassword&&user.email)
    {

      console.log("UserClick inside if",user)

      
   if(user.password===user.confirmPassword)
   {
    console.log("UserClick inside if equa;",user)


    const form=new FormData();
      form.append('fullName',user.fullName)
      form.append('title',user.title)
      form.append('twitter',user.twitter)
      form.append('linkedIn',user.linkedIn)
      form.append('email',user.email)
      form.append('password',user.password)
      form.append('location',user.location)
      form.append('photo',user.image)

      const response=await axios.post(
        `${SERVERURL}/register`,
        form
        
        );

        console.log("response",response.data.message)
        Swal.fire(
          '',
          response.data.message,
          'success'
        )

        if(response.data.success)
        {
          
          navigate('/user/verify')
        }

   }
   else{
    setValid(3)
   }

  
    }
    else{
      setValid(2)
    }
 

  }

  console.log("userSignup",user)
  return (
    <>
      <div className="mainContainerSignup">
        <div className="cardSignup">
          <div className="cardSignupInner">
            <div className="topSignupTxt">
              Welcome to TheGreenHub! We're happpy to have you.
            </div>

            <div className="tellAbtTxt">
              Tell us a little bit about yourself.
            </div>

            {continue1?<>
              <div className="topProfInLabl">
              <div>PROFILE PICTURE</div>
              <div className="topProfilePic">
                <div
                  className="profileImg"
                  style={{
                    background: imgPreview
                      ? `url("${imgPreview}")  no-repeat center/cover`
                      : "",
                  }}
                >
                 {imgPreview?"": <img src={uploader} />}
                </div>

                <input
                  type="file"
                  id="file"
                  accept="image/png , image/jpeg, image/webp"
                  class="inputfile"
                  onChange={handleImageChange}
                />
                <label for="file" className="chooseImageTxt">
                  Upload Image
                </label>
              </div>
            </div>

            <div className="topTxtInput">
              <div className="inputLbl">Full Name*</div>
              <input placeholder="Elon Musk" className="inputSignup" 
              
              onChange={(e)=>handleChange(e)}
              name="fullName"
              />
            </div>
            <div className="topTxtInput">
              <div className="inputLbl">TITLE*</div>
              <input placeholder="CEO @ Tesla" className="inputSignup" 
              
              onChange={(e)=>handleChange(e)}
              name="title"
              />
            </div>
            <div className="topTxtInput">
              <div className="inputLbl">LOCATION</div>
              <input placeholder="San Francisco" className="inputSignup"
              
              onChange={(e)=>handleChange(e)}
              name="location"
              />
            </div>
            <div className="topTxtInput">
              <div className="inputLbl">TWITTER</div>
              <input
                placeholder="http://www.twitter.come/elonmusk"
                className="inputSignup"
                onChange={(e)=>handleChange(e)}
                name="twitter"
              />
            </div>
            <div className="topTxtInput">
              <div className="inputLbl">LINKEDIN</div>
              <input
                placeholder="http://www.linkin.come/elonmusk"
                className="inputSignup"
                onChange={(e)=>handleChange(e)}
                name="linkedIn"
              />
            </div>
            {valid===1&&<div style={{color:"red"}}>Enter all fields</div>}
            <div className="home-content--btn2"
            onClick={continueClicked}
            >
              <div className="hover-btn2"></div>
              <button className="content-btn--inner2">
                <p>Continue</p>
              </button>
            </div>
            </>:<>
            <div className="topTxtInput">
              <div className="inputLbl">Email*</div>
              <input placeholder="Elon Musk@gmail.com"type="email" className="inputSignup"
              
              onChange={(e)=>handleChange(e)}
              name="email"
              />
            </div>
            <div className="topTxtInput">
              <div className="inputLbl">Password*</div>
              <input placeholder="Password" className="inputSignup" type="password"
                onChange={(e)=>handleChange(e)}
                name="password"
              />
            </div>
            <div className="topTxtInput">
              <div className="inputLbl">Confirm Password*</div>
              <input placeholder="Confirm Password" className="inputSignup"  type="password"
              
              onChange={(e)=>handleChange(e)}
              name="confirmPassword"
              
              />
            </div>
            {valid===2&&<div style={{color:"red"}}>Enter all fields</div>}
            {valid===3&&<div style={{color:"red"}}>Passwords are not same</div>}
            <div className="home-content--btn2"
           onClick={signupClick}
            >
              <div className="hover-btn2"  onClick={signupClick}></div>

              <button className="content-btn--inner2"
              onClick={signupClick}
              >
             
                <p>Signup</p>
              </button>
            </div>
           </>

            }



          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
