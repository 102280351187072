import React from "react";
import { useState } from "react";
import "./HomeTopBar.css";
const HomeTopBar = () => {
  const [cross, setCross] = useState(true);

  const clickedCross = () => {
    setCross(false);
  };

  return (
    <>
      {cross && (
        <div className="mainHomTopBar">
          <div className="crossTopBar" onClick={clickedCross}>
            x
          </div>

          <div className="txtmainHomeTp">
            <span className="spTopBar"> EVENT: </span> We will be attending the
            Ecosummit in Berlin from 14-15 June 2023.
            <a
              href="https://ecosummit.net/articles/ecosummit-berlin-14-15-june-2023"
              target="_blank"
            >
              {" "}
              <span className="spTopBarEnd">Please register and join us!</span>
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default HomeTopBar;
