import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Ambassadors.css";
import sliderPic from "./../../images/sliderPic.png";
import sliderPic2 from "./../../images/sliderPic2.png";
import axios from "axios";
import sliderPic3 from "./../../images/sliderPic3.png";
import sliderPic4 from "./../../images/sliderPic4.png";
import linkedin from "./../../images/twitter.png";
import twitter from "./../../images/linkedin.png";
import { useSelector } from "react-redux";
import { getAmbassadors } from "../../redux/CustomerReducer";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import sepAmb from "./../../images/sepAmb.png";
import Slider from "react-slick";
const Ambassadors = () => {
  const [user, setUser] = useState();
  const dispatch = useDispatch();
  const ambassadorsData = useSelector(
    (state) => state?.CustomerReducer?.getAmbassadorsData
  );

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 968,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
          centerMode: false,
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(getAmbassadors());
  }, []);

  const filtered = ambassadorsData?.filter((item) => item.isVerify === true);

  console.log("filtered", filtered);
  return (
    <>
      <div className="mainContainerAmb">
        <div className="topSliderBox">
          <div className="ourAmbTxt">Our Ambassadors</div>
          <img src={sepAmb} />
          <div className="txtAmbTop">
            We believe our vision to build a top-notch ecosystem can only be
            achieved by partnerships with key members of the sustainability &
            climate change community & businesses. So if you are an active
            participant in the sustainability space, we want to hear from you
            and would encourage you to Apply to join our industry leaders and
            help us build the next unicorns of sustainability space.
          </div>
        </div>
        <div className="mainSlidersTop">
          <Slider {...settings}>
            {filtered?.map((item) => (
              <div className="sliderCard">
                <img className="sliderImgM" src={item.image} />

                <div className="topBottomTxtSliderPr">
                  <div className="headingSlider">{item.fullName} </div>
                  <div className="sliderDec">{item.title}</div>

                  <div className="topIconsAmb">
                    <a href={item.linkedIn} target="_blank">
                      {" "}
                      <img src={linkedin} />
                    </a>
                    <a href={item.twitter} target="_blank">
                      {" "}
                      <img src={twitter} />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        <Link to="/user/join/crypto">
          {" "}
          <div className="joinAmbBtn">Join our Ambassadors</div>
        </Link>
      </div>
    </>
  );
};

export default Ambassadors;
