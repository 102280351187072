import React, { useState, useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";
import "./HeaderComponent.css";
import AOS from "aos";
import backSec from "./../../images/back2.png";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { useTypewriter } from "react-simple-typewriter";
import Typewriter from "typewriter-effect";
import WhtISCryp from "../WhtISCryp/WhtISCryp";
import { Link } from "react-router-dom";
import CookiesMessage from "../../CookiesPrompt";
// import CookiesPrompt from "../../CookiesPrompt";
// ..
AOS.init();

// background-image: url('./../../images/back2.png');

// You can also pass an optional settings object
// below listed default settings
AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
  initClassName: "aos-init", // class applied after initialization
  animatedClassName: "aos-animate", // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: "ease", // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
});

const HeaderComponent = () => {
  const [stakeholder, setStakeholder] = useState(2);

  const [myState, setMyState] = useState(0);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const founderClicked = () => {
    setStakeholder(1);
  };
  const founderClicked1 = () => {
    setStakeholder(2);
  };

  const [text] = useTypewriter({
    words: ["Hello", "From", "Typewriter", "Hook!"],
    loop: 0,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMyState(1);
    }, 8000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log("screenWidth", screenWidth);
  return (
    <>
      <CookiesMessage />
      {/* <CookiesPrompt/> */}
      <div className="mainHeaderComponent">
        {/* <img src={backSec} className="imgBackSec" /> */}
        <div className="innerHeaderCompBack">
          <div className={myState == 1 ? "topTyeWriter" : ""}>
            <Typewriter
              options={
                {
                  // cursor: myState===1?"":"|"
                }
              }
              onInit={(typewriter) => {
                typewriter
                  .typeString(
                    '<span class="main" style="max-width:50px">Curating the Best <span class="spanColored" style="color:#5FA618">Sustainability Start-ups</span> <span class="spanColored" > and helping build a </span> <span class="spanColored" style="color:#5FA618"> Greener Planet </span></span>'
                  )
                  .callFunction(() => {
                    console.log("String typed out!");
                  })
                  .pauseFor(5)

                  .callFunction(() => {
                    console.log("All strings were deleted");
                  })
                  .start();
              }}
            />

            <div className="txtHeaderComp">
              We are building the one-stop platform for vetted & curated
              sustainability projects & connecting them with key investors while
              building an ecosystem for the wider climate change initiatives.
            </div>
          </div>

          <div className="topButtonFounderInves">
            <a href={screenWidth <= "450" ? "#applyHere1" : "#applyHere"}>
              {" "}
              <div className="inestBtn">Investors</div>
            </a>
            <a href={screenWidth <= "450" ? "#applyHere2" : "#applyHere"}>
              {" "}
              <div className="founderBtn" id="applyHere3">
                {" "}
                Founders
              </div>
            </a>
          </div>
        </div>

        {/* <WhtISCryp /> */}
      </div>
    </>
  );
};

export default HeaderComponent;
