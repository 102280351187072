import React from "react";
import "./AutoSlider.css";
import sliderPic1 from "./../../images/slider5.svg";
import sliderPic2 from "./../../images/slider2.png";
import sliderPic3 from "./../../images/slider3.png";
import sliderPic4 from "./../../images/slider3.png";
import { getPartners } from "../../redux/CustomerReducer";
import sliderPic5 from "./../../images/slider5.svg";
import sliderPic6 from "./../../images/slider2.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import lineSliHd from "./../../images/lineSliHd.png";
import Slider from "react-slick";
import { useEffect } from "react";
const AutoSlider = () => {
  const dispatch=useDispatch();
  const getPartnersData = useSelector(
    (state) => state?.CustomerReducer?.getPartnersData
  );
  const settings = {
    // dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    //   cssEase: "cubic-bezier(0.600, -0.280, 0.735, 0.045)",
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1624,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1174,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 845,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(()=>{


    dispatch(getPartners())
  }

  ,[])
  return (
    <>
      <div className="mainAutoSlider">
        <div className="topOurParLine">
          <div className="ourParTxt">Our Partners</div>
          <img src={lineSliHd} />
        </div>
        {/* <div className="leadingCrypTxt">
          Leading crypto ecosystems have partnered with Alliance to help
          founders build on their protocols.
        </div> */}
        <div>
          <Slider {...settings}>
          {getPartnersData?.map((item)=>(
            <div className="topSliderImgAut">
              <img src={item.image} className="sliderImgAuto" />
            </div>
          ))  }
            
          </Slider>
        </div>
      </div>
    </>
  );
};

export default AutoSlider;
