import React from "react";
import "./FAQ.css";
import Accordion from "react-bootstrap/Accordion";
import lineFaq from './../../images/lineFaq.png'
import accordianRight from "./../../images/accordianRight.png";
const FAQ = () => {
  return (
    <>
      <div className="mainFaq" id="mainFaq">
        <div className="mainAccordian">
          <div className="topFaqLine">
            <div className="faqTxt">Frequently Asked Questions</div>
            <img src={lineFaq}/>
          </div>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="headerTxtAcc">
               How do I join TheGreenHub?
              </Accordion.Header>
              <Accordion.Body className="bosyAccordTxt">
              Investors:  You can just fill in the form and we will get in touch with you to discuss your requirements. Founders:  Please apply by sending details and white paper of your start-up and we will contact you for the next steps.
Ambassador: Please feel free to apply with your details and we will be in touch with you to discuss the next steps.

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="headerTxtAcc">
              Are there any subscription to join TheGreenHub?
              </Accordion.Header>
              <Accordion.Body className="bosyAccordTxt">
              Currently there are no paid subscriptions and you can enjoy all benefits free of cost. As and when there would be any subscriptions in the future, all registered members will be informed accordingly.

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className="headerTxtAcc">
              How can I be interviewed for your podcast?
              </Accordion.Header>
              <Accordion.Body className="bosyAccordTxt">
              Usually we will be inviting selected speakers to join our investor & founder interviews. You may contact us if you want to participate in our podcast.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header className="headerTxtAcc">
              Are you planning to launch your tokens or NFTs?
              </Accordion.Header>
              <Accordion.Body className="bosyAccordTxt">
              We have plans to launch tokens and NFTs to facilitate the engagement with our communities and ecosystem partners and all registered members will be informed prior to any such launch.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

    
      </div>
    </>
  );
};

export default FAQ;